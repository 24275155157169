import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "store/types/registration";

interface AccSettingsProps {
  isLoading: boolean;
  error: string | null;
  users: IUser[];
}

const initialState: AccSettingsProps = {
  isLoading: false,
  error: null,
  users: [],
};

export const accountSettingsSlice = createSlice({
  name: "accountSettings",
  initialState,
  reducers: {
    getState: (state) => {
      return state;
    },
  },
});

export const { getState } = accountSettingsSlice.actions;

export default accountSettingsSlice.reducer;
