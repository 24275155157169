import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getWorkspaceDetails, getWorkspaceList } from "api/fetchRequests/accDets";
import { getGlobalWorkspace } from "axiosConfig";
import { IWorkspace, IWorkspaceDets, WorkspacesState } from "store/types/workspaces";

const initialState: WorkspacesState = {
  workspaces: [],
  currentWorkspace: { id: 0, name: "", avatar: null, users: [] },
  isLoading: false,
  isDetailsLoading: false,
  error: null,
};

export const fetchWorkspaceDetails = createAsyncThunk(
  "fetchWorkspaceDetails",
  async ({ id, params }: { id: number; params?: { role?: string; user?: string } }) => {
    try {
      const res = await getWorkspaceDetails(id, params);
      return { workspace: res.data };
    } catch (err: any) {
      console.log(err);
    }
  },
);

export const fetchWorkspaces = createAsyncThunk("fetchWorkspaces", async () => {
  try {
    const currentWorkspaceId = getGlobalWorkspace();
    const resWorkspace = await getWorkspaceList();
    const currentWorkspace = resWorkspace.data.find(
      (workspace) => workspace.id === currentWorkspaceId,
    );
    if (resWorkspace.data.some((workspace: IWorkspace) => workspace.id === currentWorkspaceId)) {
      return { workspaces: resWorkspace.data, currentWorkspace };
    } else {
      window.location.href = "/404";
    }
  } catch (err) {
    console.log(err);
  }
});
export const workspacesSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    setWorkspaceUsers: (state, { payload }) => {
      const updatedWorkspace = { ...state.currentWorkspace, users: payload } as IWorkspaceDets;
      state.currentWorkspace = updatedWorkspace;
    },
    setCurrentWorkspace: (state, { payload }) => {
      state.currentWorkspace = payload;
    },
    updateCurrentWorkspace: (state, { payload }) => {
      const newWorkspaces = state.workspaces.map((workspace) =>
        workspace.id === payload.id ? payload : workspace,
      );
      state.workspaces = newWorkspaces;
      state.currentWorkspace = payload;
    },
    clearWorkspacesState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWorkspaces.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWorkspaces.fulfilled, (state, { payload }) => {
        state.workspaces = payload?.workspaces;
        if (!state.currentWorkspace.id) {
          state.currentWorkspace = payload?.currentWorkspace;
        }
        state.isLoading = false;
      })
      .addCase(fetchWorkspaces.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchWorkspaceDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchWorkspaceDetails.fulfilled, (state, { payload }) => {
        state.isDetailsLoading = false;
        state.currentWorkspace = { ...payload?.workspace };
      })
      .addCase(fetchWorkspaceDetails.rejected, (state, action) => {
        state.isDetailsLoading = false;
      });
  },
});

export const {
  clearWorkspacesState,
  setWorkspaceUsers,
  updateCurrentWorkspace,
  setCurrentWorkspace,
} = workspacesSlice.actions;

export default workspacesSlice.reducer;
