import { createSlice } from "@reduxjs/toolkit";
import { CommentsState } from "store/types/comments";

const initialState: CommentsState = {
  shouldEditComment: null,
};

export const commentsSlice = createSlice({
  name: "commentsSlice",
  initialState,
  reducers: {
    setEditComment: (state, { payload }) => {
      state.shouldEditComment = payload;
    },
    clearCommentsState: () => initialState,
  },
});

export const { clearCommentsState, setEditComment } = commentsSlice.actions;

export default commentsSlice.reducer;
