import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProjects } from "api/fetchRequests/projects";
import { setGlobalWorkspace } from "axiosConfig";
import { RootState } from "store/store";
import { HomeState } from "store/types/home";

const initialState: HomeState = {
  projects: [],
  modalType: "",
  isProjectsModalOpen: false,
  selectedProject: null,
  isLoading: false,
  error: null,
};
export const fetchProjects = createAsyncThunk(
  "fetchProjects",
  async (
    { workspace, errorCallback }: { workspace: number; errorCallback?: () => void },
    { getState },
  ) => {
    const state = getState() as RootState;
    const userInfo = state.registrationPage.user_info;
    try {
      const res = await getProjects();
      localStorage.setItem("workspace", JSON.stringify(workspace));
      return res.data;
    } catch (error: any) {
      if (error.response.status === 403 && userInfo && userInfo.own_workspace) {
        setGlobalWorkspace(userInfo.own_workspace);
      }
      if (typeof errorCallback === "function") {
        errorCallback();
      }
    }
  },
);
export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    openProjectsModal: (state, { payload }) => {
      return { ...state, isProjectsModalOpen: true, modalType: payload };
    },
    closeProjectsModal: (state) => {
      return {
        ...state,
        isProjectsModalOpen: false,
        modalType: "",
        selectedProject: null,
      };
    },
    createNewProject: (state, { payload }) => {
      state.projects.push(payload);
    },
    editProject: (state, { payload }) => {
      const itemIndex = state.projects.findIndex((project) => project.id === payload.id);
      state.projects[itemIndex].name = payload.name;
      state.projects[itemIndex].photo = payload.image;
      state.projects[itemIndex].description = payload.description;
    },
    selectProject: (state, { payload }) => {
      return {
        ...state,
        selectedProject: { ...payload },
      };
    },
    setModalType: (state, { payload }) => {
      return {
        ...state,
        modalType: payload,
      };
    },
    deleteProject: (state, { payload }) => {
      const updatedProjects = state.projects.filter((project) => project.id !== payload);
      state.projects = updatedProjects;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProjects.pending, (state) => {
        return { ...state, isLoading: true };
      })
      .addCase(fetchProjects.fulfilled, (state, { payload }) => {
        state.projects = payload;
        state.isLoading = false;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        return { ...state, isLoading: false, error: action.error.message };
      });
  },
});

export const {
  openProjectsModal,
  closeProjectsModal,
  createNewProject,
  selectProject,
  setModalType,
  editProject,
  deleteProject,
} = homeSlice.actions;

export default homeSlice.reducer;
