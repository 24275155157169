import { API } from "api/apiURLs";
import { customFetcher } from "api/fetcher";

const fetcherApplicationJson = customFetcher("application/json");

export type KanbanParams = {
  assignee?: string;
  priority?: string;
  nsearch?: string;
};

export const getKanbanData = async (id: number, workspace: number, params?: KanbanParams) => {
  return await fetcherApplicationJson.get(API.getKanbanData(id), {
    headers: { workspace },
    params,
  });
};

export const getKanbanSprintData = async (id: number, workspace: number, params?: KanbanParams) => {
  return await fetcherApplicationJson.get(API.getKanbanSprintData(id), {
    headers: { workspace },
    params,
  });
};

export const getColumns = async (id: number, workspace: number) => {
  return await fetcherApplicationJson.get(API.getColumns(id), { headers: { workspace } });
};

export const getSprints = async (id: number) => {
  return await fetcherApplicationJson.get(API.getSprints(id));
};
export const createSprint = async (data: any) => {
  return await fetcherApplicationJson.post(API.createSprints(), data);
};
export const updateSprint = async (id: number, data: any) => {
  return await fetcherApplicationJson.patch(API.currentSprintAPI(id), data);
};

export const moveItemsToSprint = async (id: number, data: any) => {
  return await fetcherApplicationJson.patch(API.moveToSprint(id), data);
};

export const deleteSprint = async (id: number) => {
  return await fetcherApplicationJson.delete(API.currentSprintAPI(id));
};
export const getBacklog = async (id: number) => {
  return await fetcherApplicationJson.get(API.backlogAPI(id));
};
