import { ScreenStatuses } from "constants/kanban";
import { IColumn, IStatus, KanbanElement, KanbanScreen } from "store/types/kanban";

export const getColumnHeight = (columns: IColumn) => {
  let maxLength = 0;
  const gap = 5;

  for (const column of columns.values()) {
    const items = column.items;
    maxLength = Math.max(maxLength, items.length);
  }

  return maxLength * 170 + (maxLength ? 10 : 0) + gap * (maxLength - 1);
};

export const removeElementFromScreen = (screen: any, elementId: number) => {
  if (screen) {
    const newItems = screen.elements.filter((item) => item.id !== elementId);
    return { ...screen, elements: newItems };
  }
};

export const getStatuses = (statuses: IStatus[]) => {
  return statuses.map((status) => {
    const currentStatus = ScreenStatuses.find((item) => (item.label === status.name ? item : null));
    if (currentStatus && "icon" in currentStatus) {
      return { label: status.name, value: status.name, id: status.id, icon: currentStatus.icon };
    } else {
      return { label: status.name, value: status.name, id: status.id, icon: "bookmarkIcon" };
    }
  });
};

const getSearchEntity = (screen: any, searchVal: string) => {
  const searchEntity = screen.name?.toLowerCase().includes(searchVal.toLowerCase())
    ? screen.name
    : screen.slug;
  return searchEntity;
};
// TODO KANBAN FIELDS
export const filterScreens = (searchVal: string, screens?: KanbanScreen[]) => {
  if (screens) {
    const filteredScreens = screens.filter((screen) => {
      const searchEntity = getSearchEntity(screen, searchVal);
      const screenItems = screen.elements;

      if (
        searchEntity.toLowerCase().includes(searchVal.toLowerCase()) ||
        screenItems.some((item) => {
          const searchEntity = getSearchEntity(item, searchVal);
          return searchEntity.toLowerCase().includes(searchVal.toLowerCase());
        })
      ) {
        return screen;
      }
    });
    return filteredScreens;
  }
  return [];
};

export const createColumns = (statuses: IStatus[]) => {
  const columnsMap = new Map<number, IStatus>();

  statuses.forEach((status) => {
    columnsMap.set(status.id, status);
  });

  return columnsMap;
};

export const mergeItemsIntoObject = (columns: IColumn, items: (KanbanElement | KanbanScreen)[]) => {
  if (items) {
    const mapValues = [...columns.values()];
    const values = mapValues.map((value) => {
      return {
        ...value,
        items: items.filter((el) => el.status === value.id),
      };
    });
    return createColumns(values);
  }
  return columns;
};
