import { createSlice } from "@reduxjs/toolkit";
import { IMergeScreens } from "store/types/mergeScreens";

const initialState: IMergeScreens = {
  figmaScreens: [],
  mergedScreens: [],
  selectedItem: null,
  selectedImage: null,
};

export const mergeScreensSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setFigmaScreens: (state, { payload }) => {
      state.figmaScreens = payload;
    },
    setMergedScreens: (state, { payload }) => {
      state.mergedScreens = payload;
    },
    setSelectedItem: (state, { payload }) => {
      state.selectedItem = payload;
    },
    setSelectedImage: (state, { payload }) => {
      state.selectedImage = payload;
    },
    clearState: () => initialState,
  },
});

export const { setFigmaScreens, setMergedScreens, setSelectedItem, setSelectedImage, clearState } =
  mergeScreensSlice.actions;

export default mergeScreensSlice.reducer;
