export const STATUSES = {
  todo: "To Do",
  inProgress: "In Progress",
  forApproval: "For Approval",
  done: "Done",
};

export const getStatusIcon = (value: string) => {
  switch (value) {
    case STATUSES.todo:
      return "toDoIcon";
    case STATUSES.inProgress:
      return "inProgressIcon";
    case STATUSES.forApproval:
      return "forApprovalIcon";
    case STATUSES.done:
      return "doneIcon";

    default:
      return "bookmarkIcon";
  }
};

export const ScreenStatuses: NoIdStatus[] = [
  {
    label: "To Do",
    value: STATUSES.todo,
    icon: getStatusIcon(STATUSES.todo),
  },

  {
    label: "In Progress",
    value: STATUSES.inProgress,
    icon: getStatusIcon(STATUSES.inProgress),
  },
  {
    label: "For Approval",
    value: STATUSES.forApproval,
    icon: getStatusIcon(STATUSES.forApproval),
  },
  {
    label: "Done",
    value: STATUSES.done,
    icon: getStatusIcon(STATUSES.done),
  },
];

export interface NoIdStatus {
  label: string;
  value: string;
  icon: string;
}
export interface IScreenStatus extends NoIdStatus {
  id: number;
}

export const SIDEBAR_HEIGHT = 400;

export const BOARD_HEADER_SMALL = 144;
export const BOARD_HEADER_BIG = 188;
