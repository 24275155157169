import { ISprint } from "store/types/backlog";

export const BACKLOG: ISprint = {
  id: 0,
  name: "Backlog",
  goal: "",
  duration: 9999,
  start_date: JSON.stringify(new Date()),
  end_date: JSON.stringify(new Date()),
  is_closed: false,
  is_active: false,
  project: 0,
  items: [],
};
