import { accountSettingsSlice } from "./reducers/AccountSettings";
import { homeSlice } from "./reducers/Home";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { registrationSlice } from "./reducers/Registration";
import { projectSlice } from "./reducers/Project";
import { contextMenuSlice } from "./reducers/ContextMenu";
import { mergeScreensSlice } from "./reducers/MergeScreens";
import { sidebarSlice } from "./reducers/Sidebar";
import { commentsSlice } from "./reducers/Comments";
import { kanbanSlice } from "./reducers/Kanban";
import { workspacesSlice } from "./reducers/Workspaces";
import { requirementsSlice } from "./reducers/Requirements";
import { backlogSlice } from "./reducers/Backlog";

export const store = configureStore({
  reducer: {
    registrationPage: registrationSlice.reducer,
    homePage: homeSlice.reducer,
    projectPage: projectSlice.reducer,
    contextMenu: contextMenuSlice.reducer,
    accountSettings: accountSettingsSlice.reducer,
    mergeScreens: mergeScreensSlice.reducer,
    kanbanPage: kanbanSlice.reducer,
    sidebar: sidebarSlice.reducer,
    comments: commentsSlice.reducer,
    workspaces: workspacesSlice.reducer,
    requirements: requirementsSlice.reducer,
    backlog: backlogSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
