import { API } from "api/apiURLs";
import { customFetcher } from "api/fetcher";

const fetcherApplicationJson = customFetcher("application/json");
const fetcherMultipartData = customFetcher("multipart/form-data");

const updateAccountDetails = async (id: string, data: any, options?: any) => {
  const res = await fetcherMultipartData.patch(API.profileUpdate(id), data, { ...options });
  return res;
};

const getUser = async (id: string) => {
  const res = await fetcherApplicationJson.get(API.userData(id));
  return res;
};

export const checkFigmaToken = async (userId: string) => {
  const res = await fetcherApplicationJson.get(API.checkFigmaToken(userId));
  return res;
};

export { updateAccountDetails, getUser };
