// import { showNotification } from "@mantine/notifications";
import { ReactComponent as TickIcon } from "icons/tick-notify-icon.svg";
import { notifications } from "@mantine/notifications";

const useNotifications = () => {
  const successNotify = ({ message }: { message: JSX.Element | string }) => {
    notifications.show({
      title: "Success",
      message,
      autoClose: 5000,
      icon: <TickIcon width={18} height={18} />,
      color: "teal",
    });
  };
  const errorNotify = (error: string | any) => {
    const message =
      typeof error === "string"
        ? error
        : "response" in error
        ? (Object.values(error.response?.data).flat()[0] as string)
        : error.message;
    notifications.show({
      title: "Oops! You have something wrong!",
      message,
      autoClose: 5000,
      color: "red",
    });
  };
  const pushNotify = (title: string, message: string) => {
    notifications.show({
      title,
      message: <div dangerouslySetInnerHTML={{ __html: message }} />,
      autoClose: 10000,
    });
  };
  return { successNotify, errorNotify, pushNotify };
};
export const successNotify = ({ message }: { message: JSX.Element | string }) => {
  notifications.show({
    title: "Success",
    message,
    autoClose: 5000,
    icon: <TickIcon width={18} height={18} />,
    color: "teal",
  });
};

export const errorNotify = (error: string | any) => {
  const message =
    typeof error === "string"
      ? error
      : "response" in error
      ? (Object.values(error.response?.data).flat()[0] as string)
      : error.message;
  notifications.show({
    title: "Oops! You have something wrong!",
    message,
    autoClose: 5000,
    color: "red",
  });
};

export default useNotifications;
