import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  KanbanParams,
  getColumns,
  getKanbanData,
  getKanbanSprintData,
  getSprints,
} from "api/fetchRequests/kanban";
import { IColumn, KanbanElement, KanbanGroup, KanbanScreen, KanbanState } from "store/types/kanban";
import { createColumns, getStatuses } from "utils/kanban";
import { setStatuses } from "./Sidebar";
import { getAllElements } from "utils/projectUtils.";
import { RootState } from "store/store";
import { setSprintsItems } from "./Backlog";
import { errorNotify } from "hooks/useNotifications";
import { ISprint } from "store/types/backlog";
import { omit } from "utils";

const initialState: KanbanState = {
  isLoading: false,
  error: "",
  current_project_id: 0,
  updated_at: "",
  name: "",
  workspace: 0,
  columns: new Map(),
  statuses: [],
  description: "",
  groups: [],
  ungroupedScreens: [],
  currentGroupTasks: [],
  selectedGroup: null,
  allGroupsDescendant: [],
  isOpen: false,
  sidebarElement: null,
  isTaskModalOpen: false,
  isTask: false,
  screensSearchVal: "",
  advancedView: false,
  filters: [],
  activeSprints: [],
};

export const fetchKanbanData = createAsyncThunk(
  "fetchKanbanData",
  async (
    {
      id,
      workspace,
      params,
    }: {
      id: number;
      workspace: number;
      params?: KanbanParams;
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const kanbanStatuses = state.kanbanPage.statuses;
    try {
      const sprintsRes = await getSprints(id);
      const activeSprints = sprintsRes.data.filter((item) => item.is_active);

      let columns: { id: number; name: string; items: any[] }[] = [];
      let newColumns = new Map();
      if (!kanbanStatuses.length) {
        const res = await getColumns(id, workspace);
        columns = res.data;
        newColumns = createColumns(columns);
        const statuses = getStatuses(columns);
        dispatch(setStatuses(statuses));
      }

      let allDescendant = [];
      let groups = [];
      const res = activeSprints.length
        ? await getKanbanSprintData(id, workspace, params)
        : await getKanbanData(id, workspace, params);
      allDescendant = res.data.groups
        .map((el: any) => {
          return getAllElements(el);
        })
        .flat(1);

      groups = res.data.groups;
      const validSprints: ISprint[] = sprintsRes.data.map((sprint) =>
        omit(
          {
            ...sprint,
            items: [...sprint.screens, ...sprint.elements],
          },
          ["screens", "elements"],
        ),
      );
      dispatch(setSprintsItems(validSprints));
      if (!kanbanStatuses.length) {
        return {
          groups: groups,
          statuses: columns,
          columns: newColumns,
          allGroupsDescendant: allDescendant,
          activeSprints,
        };
      }
      return {
        groups: groups,
        allGroupsDescendant: allDescendant,
        activeSprints,
      };
    } catch (error) {
      errorNotify(error);
      console.log(error);
      return { groups: [] };
    }
  },
);

export const kanbanSlice = createSlice({
  name: "kanban",
  initialState,
  reducers: {
    setSelectedGroup: (state, { payload }) => {
      state.selectedGroup = payload;
    },
    setGroups: (state, { payload }) => {
      const allDescendant = payload
        .map((el: any) => {
          return getAllElements(el);
        })
        .flat(1);
      state.groups = payload;
      state.allGroupsDescendant = allDescendant;
    },

    setCurrentGroupTasks: (state, { payload }) => {
      state.currentGroupTasks = payload;
    },
    setFilters: (state, { payload }) => {
      const newFilters = state.filters.some((filter) => filter.value === payload.value)
        ? state.filters.filter((el) => el.value !== payload.value)
        : [...state.filters, payload];

      state.filters = newFilters;
    },
    setScreensSearchVal: (state, { payload }) => {
      state.screensSearchVal = payload;
    },

    toggleTaskModal: (state, { payload }) => {
      state.isTaskModalOpen = payload;
    },
    setIsTask: (state, { payload }) => {
      state.isTask = payload;
    },
    setView: (state, { payload }) => {
      state.advancedView = payload;
    },
    clearKanbanState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchKanbanData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchKanbanData.fulfilled, (state, { payload }) => {
        if (payload?.columns) {
          state.columns = payload.columns as IColumn;
          state.groups = payload.groups;
          state.allGroupsDescendant = payload.allGroupsDescendant;
          state.statuses = payload?.statuses;
          state.activeSprints = payload.activeSprints;
        } else {
          state.groups = payload.groups;
          state.allGroupsDescendant = payload.allGroupsDescendant as (
            | KanbanGroup
            | KanbanScreen
            | KanbanElement
          )[];
          state.activeSprints = payload.activeSprints;
        }
        state.isLoading = false;
      })
      .addCase(fetchKanbanData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  clearKanbanState,
  setCurrentGroupTasks,
  setSelectedGroup,
  setGroups,
  toggleTaskModal,
  setIsTask,
  setScreensSearchVal,
  setView,
  setFilters,
} = kanbanSlice.actions;

export default kanbanSlice.reducer;
