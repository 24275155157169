import { updateElement, updateGroup, updateScreen } from "api/fetchRequests/project";
import { ENTITY_TYPES } from "constants/projects";
import React from "react";
import { setActiveElement, setActiveScreen } from "store/reducers/Requirements";

interface UpdateFunctions {
  [key: string]: (slug: string, data: any) => Promise<any>;
}

export const delay = (ms: number) => {
  return new Promise((res) => setTimeout(res, ms));
};

export const getFormattedDate = (dateString: string) => {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const timeSince = (date: Date): string => {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return interval + " years ago";
  }

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + " months ago";
  }

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + " days ago";
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + " hours ago";
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + " minutes ago";
  }

  return Math.floor(seconds) + " seconds ago";
};

const getSearchEntity = (entity: any, searchVal: string) => {
  const searchEntity = entity.name?.toLowerCase().includes(searchVal.toLowerCase())
    ? entity.name
    : entity.slug;
  return searchEntity;
};

const searchInsideGroup = (group: any, searchVal: string) => {
  const searchEntity = getSearchEntity(group, searchVal);

  if (!searchEntity.toLowerCase().includes(searchVal.toLowerCase()) && group.screens?.length) {
    return group.screens.some((screen: Record<any, any>) => {
      const item = searchInsideScreen(screen, searchVal);
      return item;
    });
  }
  return searchEntity.toLowerCase().includes(searchVal.toLowerCase());
};

const searchInsideScreen = (screen: any, searchVal: string) => {
  const searchEntity = getSearchEntity(screen, searchVal);

  if (!searchEntity.toLowerCase().includes(searchVal.toLowerCase()) && screen.elements?.length) {
    return screen.elements.some((element: Record<any, any>) => {
      const elementSearchEntity = getSearchEntity(element, searchVal);
      return elementSearchEntity?.toLowerCase().includes(searchVal.toLowerCase());
    });
  }
  return searchEntity.toLowerCase().includes(searchVal.toLowerCase());
};

const filterItems = (items: any[], searchVal: string) => {
  const filteredItems = items.map((item) => {
    if (item.item_type === ENTITY_TYPES.group) {
      if (
        item.screens.some((screen: Record<any, any>) => {
          const searchEntity = getSearchEntity(screen, searchVal);
          return searchEntity.toLowerCase().includes(searchVal.toLowerCase());
        })
      ) {
        const newScreens = item.screens.filter((screen: Record<any, any>) => {
          const searchEntity = getSearchEntity(screen, searchVal);

          return searchEntity.toLowerCase().includes(searchVal.toLowerCase());
        });
        return { ...item, screens: newScreens };
      }
      if (
        // CHECK ELEMENTS
        !item.screens.some((screen: Record<any, any>) => {
          const searchEntity = getSearchEntity(screen, searchVal);
          return searchEntity.toLowerCase().includes(searchVal.toLowerCase());
        })
      ) {
        const newScreens = item.screens.map((screen: Record<any, any>) => {
          if (
            screen.elements.some((item: Record<any, any>) => {
              const searchEntity = getSearchEntity(item, searchVal);
              return searchEntity.toLowerCase().includes(searchVal.toLowerCase());
            })
          ) {
            const newItems = screen.elements.filter((item: Record<any, any>) => {
              const searchEntity = getSearchEntity(item, searchVal);

              return searchEntity.toLowerCase().includes(searchVal.toLowerCase());
            });
            return { ...screen, items: newItems };
          }
          return null;
        });
        return {
          ...item,
          screens: newScreens.filter((screen: Record<any, any>) => screen !== null),
        };
      }
    }
    if (item.item_type === ENTITY_TYPES.screen) {
      return item;
    }
  });
  return filteredItems;
};

export const searchItemsByVal = (items: any[], searchVal: string) => {
  if (searchVal.length) {
    const newItems = items.filter((item) => {
      // GROUP
      if (item.item_type === ENTITY_TYPES.group) {
        const foundItem = searchInsideGroup(item, searchVal);
        return foundItem;
      }

      // SCREENS
      if (item.item_type === ENTITY_TYPES.screen) {
        const foundItem = searchInsideScreen(item, searchVal);
        return foundItem;
      }
    });

    const filteredItems = filterItems(newItems, searchVal);

    return filteredItems;
  }
  return items;
};

export const getCapitalLetter = (text?: string) => {
  if (text) {
    return text.slice(0, 1).toUpperCase() + text.slice(1, text.length);
  }
  return "";
};

export const omit = (obj: any, keysToOmit: string[]) => {
  const result: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && !keysToOmit.includes(key)) {
      result[key] = obj[key];
    }
  }
  return result;
};
export const updateFunctions: UpdateFunctions = {
  group: updateGroup,
  screen: updateScreen,
  element: updateElement,
};

type JSONData = {
  type: string;
  content?: JSONData[];
  text?: string;
};

export function extractText(jsonString: string) {
  if (jsonString) {
    try {
      const data: JSONData = JSON.parse(jsonString);
      let text = "";
      const extractContent = (content: JSONData[]) => {
        for (const item of content) {
          if (item.type === "text" && item.text) {
            text += item.text + " ";
          } else if (item.content) {
            extractContent(item.content);
          }
        }
      };

      if (data && data.content) {
        extractContent(data.content);
      }

      text = text.trim();
      return text;
    } catch (error) {
      return "";
    }
  }
  return "";
}

export const setActiveItem: any = {
  screen: setActiveScreen,
  element: setActiveElement,
};

export const jsonToHtml = (json: any): JSX.Element | string => {
  if (json.type === "doc") {
    return (
      <>
        {json.content.map((block: any, index: number) => (
          <React.Fragment key={index}>{jsonToHtml(block)}</React.Fragment>
        ))}
      </>
    );
  }

  if (json.type === "paragraph" && "content" in json && json.content.length !== 0) {
    const content = json.content.map((inline: any, index: number) => (
      <React.Fragment key={index}>{jsonToHtml(inline)}</React.Fragment>
    ));
    return <div style={{ margin: "5px 0" }}>{content}</div>;
  }

  if (json.type === "bulletList") {
    return (
      <ul>
        {json.content.map((item: any, index: number) => (
          <li key={index}>{jsonToHtml(item)}</li>
        ))}
      </ul>
    );
  }

  if (json.type === "listItem") {
    return (
      <p>
        {json.content.map((block: any, index: number) => (
          <React.Fragment key={index}>{jsonToHtml(block)}</React.Fragment>
        ))}
      </p>
    );
  }

  if (json.type === "orderedList") {
    const start = json.attrs && json.attrs.start ? json.attrs.start : undefined;
    return (
      <ol start={start}>
        {json.content.map((item: any, index: number) => (
          <li key={index}>{jsonToHtml(item)}</li>
        ))}
      </ol>
    );
  }

  if (json.type === "blockquote") {
    return (
      <blockquote style={{ borderLeft: "2px solid #ced4da", paddingLeft: "1rem" }}>
        {json.content.map((paragraph: any, index: number) => (
          <React.Fragment key={index}>{jsonToHtml(paragraph)}</React.Fragment>
        ))}
      </blockquote>
    );
  }

  if (json.type === "mention") {
    const mentionAttrs = json.attrs;
    return <p style={{ color: "#4040C9", padding: 0, margin: 0 }}>@{mentionAttrs.label}</p>;
  }

  if (json.type === "hardBreak") {
    return <br />;
  }

  if (json.type === "text") {
    let text = json.text || "";
    if (json.marks) {
      json.marks.forEach((mark: any) => {
        if (mark.type === "bold") {
          text = <strong key={text}>{text}</strong>;
        }
        if (mark.type === "italic") {
          text = <em key={text}>{text}</em>;
        }
        if (mark.type === "strike") {
          text = <s key={text}>{text}</s>;
        }
        if (mark.type === "link") {
          const linkAttrs = mark.attrs;
          text = (
            <a
              href={linkAttrs?.href}
              target={linkAttrs?.target}
              rel={linkAttrs?.rel || ""}
              style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
              key={text}
            >
              {text}
            </a>
          );
        }
      });
    }
    if (typeof text === "string") {
      return (
        <>
          {text.split(" ").map((word, index, array) => (
            <React.Fragment key={index}>
              {word}
              {index < array.length - 1 && "\u00A0"}
            </React.Fragment>
          ))}
        </>
      );
    }
    return text;
  }

  return "";
};

export const convertToSeconds = (hours: number, minutes: number) => {
  const hoursInSeconds = hours * 3600;
  const minutesInSeconds = minutes * 60;
  const totalSeconds = hoursInSeconds + minutesInSeconds;

  return totalSeconds;
};

export const removeSegments = (count: number) => {
  const currentPath = window.location.pathname;
  const pathSegments = currentPath.split("/");
  const newPathSegments = pathSegments.slice(0, -count);
  const newPath = newPathSegments.join("/");
  return newPath;
};

export const secondsToHoursAndMinutes = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return {
    hours: hours,
    minutes: minutes,
  };
};

export const convertSecondsToWdhm = (seconds: number) => {
  const weeks = Math.floor(seconds / (3600 * 24 * 7));
  const days = Math.floor((seconds % (3600 * 24 * 7)) / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  let parts = "";
  if (weeks > 0) {
    parts = parts + ` ${weeks}w`;
  }
  if (days > 0 || (weeks > 0 && (hours > 0 || minutes > 0))) {
    parts = parts + ` ${days}d`;
  }
  if (hours > 0 || (days > 0 && minutes > 0)) {
    parts = parts + ` ${hours}h`;
  }
  if (minutes > 0) {
    parts = parts + ` ${minutes}m`;
  }

  return parts;
};

export const convertWdhmToSeconds = (wdhmString: string) => {
  const regex = /(\d+)(w|d|h|m)/g;
  let match;
  let totalSeconds = 0;

  while ((match = regex.exec(wdhmString)) !== null) {
    const value = parseInt(match[1]);
    const unit = match[2];

    switch (unit) {
      case "w":
        totalSeconds += value * 7 * 24 * 3600;
        break;
      case "d":
        totalSeconds += value * 24 * 3600;
        break;
      case "h":
        totalSeconds += value * 3600;
        break;
      case "m":
        totalSeconds += value * 60;
        break;
      default:
        throw new Error("Unknown unit");
    }
  }

  return totalSeconds;
};
export const isEqual = (objA: any, objB: any) => {
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);
  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    const valA = objA[key];
    const valB = objB[key];

    if (typeof valA === "object" && typeof valB === "object") {
      if (!isEqual(valA, valB)) {
        return false;
      }
    } else if (valA !== valB) {
      return false;
    }
  }

  return true;
};

export const getObjectFieldsString = (obj: any[], field: string) => {
  return obj.reduce((prev, current, index) => {
    return prev + (index === 0 ? "" : ", ") + current[field];
  }, "");
};
