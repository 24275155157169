import { getOptionIcon } from "utils/projectUtils.";

export const MODAL_TYPES = {
  create_edit: "create/edit",
  delete: "delete",
};

export enum ENTITY_TYPES {
  screen = "screen",
  group = "group",
  element = "element",
}

export enum KINDS {
  element = "element",
  subtask = "subtask",
  bug = "bug",
}

export const PRIORITIES = {
  lowest: "lowest",
  low: "low",
  medium: "medium",
  high: "high",
  highest: "highest",
};

export const ELEMENT_TYPES = {
  input: "input",
  button: "button",
  checkbox: "checkbox",
  datepicker: "datetime_picker",
  dropdown: "dropdown",
  fileUploader: "file_uploader",
  htmlIcon: "html_icon",
  image: "image",
  link: "link",
  map: "map",
  multilineInput: "multiline_input",
  pictureUploader: "picture_uploader",
  radioButton: "radio",
  searchBox: "search",
  shape: "shape",
  sliderInput: "slider_input",
  text: "text",
  video: "video",
};

export const ORIENTATION = {
  horizontal: "horizontal",
  vertical: "vertical",
};

export const TABS = {
  project: "project",
  board: "board",
  backlog: "backlog",
};

export const HEADER_HEIGHT = 116;
export const HEADER_HEIGHT_SMALL = 52;
export const MIN_ZOOM = 0.4;
export const MAX_ZOOM = 3.6;
export const ZOOM_DATA = [25, 50, 75, 100];

export const PrioritySelectData = [
  {
    icon: getOptionIcon(PRIORITIES.highest),
    label: "Highest",
    value: PRIORITIES.highest,
  },

  {
    icon: getOptionIcon(PRIORITIES.high),
    label: "High",
    value: PRIORITIES.high,
  },
  {
    icon: getOptionIcon(PRIORITIES.medium),
    label: "Medium",
    value: PRIORITIES.medium,
  },
  {
    icon: getOptionIcon(PRIORITIES.low),
    label: "Low",
    value: PRIORITIES.low,
  },
  {
    icon: getOptionIcon(PRIORITIES.lowest),
    label: "Lowest",
    value: PRIORITIES.lowest,
  },
];
