import { GroupElement, ProjectProps, ScreenElement } from "./../types/project";
import { delay } from "./../../utils/index";
import { findGroupsByIds, getAllElements } from "./../../utils/projectUtils.";
import { TOOLBAR_ACTIONS } from "./../../constants/buttonTypes";
import {
  getProjectPageImportStatus,
  getProjectDetails,
  getTaskStatus,
} from "./../../api/fetchRequests/project";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ORIENTATION, TABS } from "constants/projects";
import { setFigmaScreens } from "./MergeScreens";
import { getColumns } from "api/fetchRequests/kanban";
import { getStatuses } from "utils/kanban";
import { setStatuses } from "./Sidebar";
import { RootState } from "store/store";
import { AxiosResponse } from "axios";

const initialState: ProjectProps = {
  error: null,
  name: "",
  id: null,
  slug: "",
  description: "",
  photo: "",
  updated_at: "",
  isNavbarOpened: true,
  isFigmaLinkModalOpened: false,
  toolbarAction: TOOLBAR_ACTIONS.move,
  scale: 1,
  isLoading: false,
  current_project_id: null,
  is_modal_open: false,
  items: [],
  all_descendant: [],
  selected_navbar_elements: [],
  isPageLoaded: false,
  draggableItemId: null,
  inactiveSelection: [],
  new_marked_screen_id: null,
  new_item_zoom_to: null,
  orientation: ORIENTATION.horizontal,
  tab: TABS.project,
  selectedGroups: [],
};

const checkTaskStatus = async (
  taskId: string,
  dispatch: any,
  isPageLoaded: boolean,
  abortController?: AbortController,
): Promise<any> => {
  let retryCount = 0;
  const maxRetries = 1000;
  const retryInterval = 2000;

  while (retryCount < maxRetries) {
    const res = await getTaskStatus({ task_id: taskId }, { signal: abortController?.signal });

    if (res.data.status === "PENDING") {
      dispatch(setIsPageLoaded(true));
      if (window.location.pathname.includes("projects")) {
        await delay(retryInterval);
        retryCount++;
      } else {
        dispatch(setIsPageLoaded(false));
        return;
      }
    }
    if (res.data.status === "FAILURE") {
      if (isPageLoaded) {
        dispatch(setError("Import from Figma failed. Please try again later!"));
      }
      return null;
    }

    if (res.data.status === "SUCCESS") {
      dispatch(setIsPageLoaded(false));
      return res;
    }
  }
};

export const fetchProjectDetails = createAsyncThunk(
  "fetchProjectDetails",
  async (
    {
      id,
      isLoading,
      noMerge,
      workspace,
      abortController,
    }: {
      id: number;
      isLoading: boolean;
      workspace: number;
      noMerge?: boolean;
      abortController?: AbortController;
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const isPageLoaded = state.projectPage.isPageLoaded;
    try {
      if (isLoading) {
        dispatch(setIsLoading(true));
      }

      // If figma import is still in progress, repeat request after 2s delay
      // await checkImportStatus(projectResult.data.pages[0].id, dispatch);
      const projectImportStatus = await getProjectPageImportStatus(id);

      // if we hade task_id in getProjectPageImportStatus response, we need to keep track on it
      let resTaskStatus: AxiosResponse<any, any> | null = null;

      if (projectImportStatus.data.task_id) {
        resTaskStatus = await checkTaskStatus(
          projectImportStatus.data.task_id,
          dispatch,
          isPageLoaded,
          abortController,
        );
      }

      const projectResult = await getProjectDetails(id, +workspace, {
        signal: abortController?.signal,
      });

      const columns = await getColumns(id, +workspace);
      const statuses = getStatuses(columns.data);
      dispatch(setStatuses(statuses));

      if (resTaskStatus && resTaskStatus.data) {
        const items = projectResult.data.items;
        const figmaScreensIds = resTaskStatus.data.result;
        const figmaScreens = figmaScreensIds.map((id: number) => {
          const foundItem = items.find((item: ScreenElement) => item.id === id);
          if (foundItem) {
            return { id: foundItem.id, name: foundItem.name, image: foundItem.image };
          }
        });
        if (!noMerge && noMerge !== undefined) {
          dispatch(setFigmaScreens(figmaScreens));
        }
      }
      if (isLoading) {
        dispatch(setIsLoading(false));
      }

      const groups = projectResult.data.items;

      const allDescendant = groups
        .map((el: any) => {
          return getAllElements(el);
        })
        .flat(1);

      const data = {
        ...projectResult.data,
        items: groups,
        all_descendant: allDescendant,
        current_project_id: id,
      };
      return data;
    } catch (error: any) {
      console.log(error);
    }
  },
);

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    toggleNavigationBar: (state, { payload }) => {
      state.isNavbarOpened = payload;
    },
    selectItem: (state, { payload }) => {
      if (state.selected_navbar_elements.includes(payload)) {
        state.selected_navbar_elements = state.selected_navbar_elements.filter(
          (item) => item !== payload,
        );
      } else {
        state.selected_navbar_elements = [...state.selected_navbar_elements, payload];
      }
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setToolbarAction: (state, { payload }) => {
      return { ...state, toolbarAction: payload };
    },

    setIsModalOpen: (state, { payload }) => {
      return { ...state, is_modal_open: payload };
    },

    setSelectedNavbarElements: (state, { payload }) => {
      const selectedGroup = findGroupsByIds(state.items, payload);
      state.selectedGroups = selectedGroup;
      state.selected_navbar_elements = payload;
    },
    setProjectItems: (state, { payload }) => {
      state.items = payload;
      const newSelectedGroups = state.selectedGroups.map((group) => {
        if (payload.some((newGroup: GroupElement) => newGroup.id === group.id)) {
          const newGroup = payload.find(
            (payloadGroup: GroupElement) => payloadGroup.id === group.id,
          );
          return newGroup;
        } else {
          return group;
        }
      });
      state.selectedGroups = newSelectedGroups;
      state.all_descendant = payload
        .map((el: any) => {
          return getAllElements(el);
        })
        .flat(1);
    },
    setIsPageLoaded: (state, { payload }) => {
      state.isPageLoaded = payload;
    },
    setSelectedGroups: (state, { payload }) => {
      state.selectedGroups = payload;
    },
    toggleFigmaLinkModal: (state, { payload }) => {
      state.isFigmaLinkModalOpened = payload.isOpen;
    },
    setIsLoading: (state, { payload }) => {
      return { ...state, isLoading: payload };
    },

    setNewMarkedScreen: (state, { payload }) => {
      state.new_marked_screen_id = payload;
    },

    clearProject: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProjectDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload,
          isLoading: false,
          error: "",
        };
      })
      .addCase(fetchProjectDetails.rejected, (state, action) => {
        return { ...state, isLoading: false, error: action.error.message };
      });
  },
});

export const {
  toggleNavigationBar,
  setToolbarAction,
  clearProject,
  setSelectedNavbarElements,
  setIsPageLoaded,
  setIsLoading,
  setProjectItems,
  setNewMarkedScreen,
  toggleFigmaLinkModal,
  setSelectedGroups,
  setError,
  selectItem,
} = projectSlice.actions;

export default projectSlice.reducer;
