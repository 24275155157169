import { IUser, IRegistration } from "store/types/registration";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkFigmaToken, getUser } from "api/fetchRequests/users";

const initialState: IRegistration = {
  user_info: {
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    position: "",
    id: "",
    avatar: "",
    last_visited_workspace_id: null,
    own_workspace: null,
  },
  isLoading: false,
  error: null,
  currentWorkspace: null,
  hasFigmaToken: false,
};

export const fetchUser = createAsyncThunk(
  "fetchUser",
  async ({
    workspace,
    shouldCheckFigmaToken,
  }: {
    workspace: number;
    shouldCheckFigmaToken?: boolean;
  }) => {
    const userId = JSON.parse(localStorage?.getItem("user") || "{}").id;
    try {
      const res = await getUser(userId);
      let checkRes: any = false;
      if (shouldCheckFigmaToken) {
        checkRes = await checkFigmaToken(userId);
      }
      const userInfo = {
        id: res.data.id,
        own_workspace: res.data.own_workspace,
        last_visited_workspace_id: res.data.last_visited_workspace_id,
      };
      localStorage.setItem("user", JSON.stringify(userInfo));
      if (workspace) {
        return {
          user_info: res.data as IUser,
          currentWorkspace: +workspace,
          hasFigmaToken: shouldCheckFigmaToken ? checkRes.data.has_figma_token : false,
        };
      } else {
        return { user_info: res.data as IUser };
      }
    } catch (err) {
      console.log(err);
    }
  },
);

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    signUp: (state, { payload }) => {
      state.user_info = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    updateHasFigmaToken: (state, { payload }) => {
      state.hasFigmaToken = payload;
    },
    updateUserInfo: (state, { payload }) => {
      state.user_info = { ...state.user_info, ...payload };
    },
    clearUserState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        return { ...state, isLoading: true };
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        return { ...state, ...payload, isLoading: false };
      })
      .addCase(fetchUser.rejected, (state, action) => {
        return { ...state, isLoading: false, error: action.error.message };
      });
  },
});

export const { signUp, setIsLoading, updateUserInfo, clearUserState, updateHasFigmaToken } =
  registrationSlice.actions;

export default registrationSlice.reducer;
