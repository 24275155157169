import { createSlice } from "@reduxjs/toolkit";

export interface ContextMenuProps {
  show: boolean;
  x: number;
  y: number;
}

const initialState: ContextMenuProps = {
  show: false,
  x: 0,
  y: 0,
};

export const contextMenuSlice = createSlice({
  name: "contextMenu",
  initialState,
  reducers: {
    setMenuData: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { setMenuData } = contextMenuSlice.actions;

export default contextMenuSlice.reducer;
