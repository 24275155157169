export const BUTTON_TYPES = {
  primary: "primary",
  secondary: "secondary",
  action: "action",
  textBased: "textBased",
  destructive: "destructive",
  link: "link",
};

export const BUTTON_VARIANTS = {
  addButton: "addButton",
  chevronButton: "chevronButton",
};

export const TOOLBAR_ACTIONS = {
  select: "select",
  move: "move",
  uploadScreens: "uploadScreens",
  changeScale: "changeScale",
  markItem: " markItem",
  hideElements: "hideElements",
  none: "none",
};
