import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { getGlobalWorkspace } from "axiosConfig";

const API_PATH = process.env.REACT_APP_BASE_URL;

const redirectsToHome = [
  "upload-screens",
  "check-figma-import-status",
  "create-group",
  "create-screen",
  "duplicate-items",
  "export-time-log",
  "export",
  "bulk-delete",
  "screens",
  "screen-groups",
  "elements",
  "board",
];

export const customFetcher = (contentType: string) => {
  const fetcher = axios.create({
    // your other properties for axios instance
    baseURL: API_PATH,
    headers: {
      "Content-Type": contentType,
    },
  });
  fetcher.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const workspace = getGlobalWorkspace();
    const token = localStorage.getItem("jwt")?.replaceAll(`"`, "");
    config.headers = config.headers ?? {};
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    if (workspace) {
      config.headers["workspace"] = `${workspace}`;
    }
    return config;
  });
  fetcher.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      if (
        error.response?.status === 404 &&
        redirectsToHome.some((item) => error.response?.config.url?.includes(item))
      ) {
        const workspace = getGlobalWorkspace();
        window.location.href = `/${workspace}/home?error=no_project`;
      }
      if (error.response?.status === 401 && !error.response.config.url?.includes("devices")) {
        const currentSearchParams = new URLSearchParams(window.location.search);
        const currentKeyParam = currentSearchParams.get("invite_key");
        const redirectURL = currentKeyParam ? `/login?invite_key=${currentKeyParam}` : "/login";
        localStorage.setItem("jwt", "");
        localStorage.setItem("user", "");
        window.location.href = redirectURL;
      }
      return Promise.reject(error);
    },
  );

  return fetcher;
};
