import { AxiosResponse } from "axios";
import { API } from "../apiURLs";
import { customFetcher } from "api/fetcher";

const fetcherApplicationJson = customFetcher("application/json");
const fetcherMultipartData = customFetcher("multipart/form-data");
interface IParams {
  role?: string;
  user?: string;
}

export const getWorkspaceList = async () => {
  const res = await fetcherApplicationJson.get(API.getWorkspaceList());
  return res;
};

type createWorkspaceType = {
  id: string;
} & Record<string, any>;

export const createWorkspace = async (data: { name: string }, options?: any) => {
  const res: AxiosResponse<createWorkspaceType> = await fetcherApplicationJson.post(
    API.createWorkspace(),
    data,
    { headers: { ...options } },
  );
  return res;
};

export const getWorkspaceDetails = async (id: number, params?: IParams) => {
  const res = await fetcherApplicationJson.get(API.getWorkspaceDetails(id), { params });
  return res;
};

export const removeUser = async (id: number, data: { user_id: string }) => {
  const res = await fetcherApplicationJson.post(API.removeUser(id), data);
  return res;
};

export const changeMemberRole = async (id: number, data: { user_id: string; role: string }) => {
  const res = await fetcherApplicationJson.post(API.changeRole(id), data);
  return res;
};
export const changeMemberProjects = async (
  id: number,
  data: { user_id: string; project_ids: number[]; full_access: boolean },
) => {
  const res = await fetcherApplicationJson.post(API.changeProjects(id), data);
  return res;
};

export const inviteUsers = async (
  id: number,
  data: { role: string; emails: string[]; project_ids: number[]; full_access: boolean },
) => {
  const res = await fetcherApplicationJson.post(API.inviteUsers(id), data);
  return res;
};

export const acceptInvite = async (data: { key: string }) => {
  const res = await fetcherApplicationJson.post(API.acceptInvite(), data);
  return res.data;
};

export const checkInviteKey = async (data: { invite_key: string }) => {
  const res = await fetcherApplicationJson.post(API.checkInviteKey(), data);
  return res.data;
};

export const updateWorkspace = async (id: number, data: any) => {
  const res = await fetcherMultipartData.patch(API.updateWorkspace(id), data);
  return res;
};
