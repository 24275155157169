import { ComponentType } from "react";

export const getRoute = (route: string) => {
  const workspaceId = window.location.pathname.split("/").filter((el) => el)[0];
  return `/${workspaceId}/${route === "/" ? "" : route}`;
};

export const lazyRetry = (
  componentImport: () => Promise<{ default: ComponentType<any> }>,
  name: string,
) => {
  return new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false",
    );
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "false");
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(`retry-${name}-refreshed`, "true");
          return window.location.reload();
        }
        reject(error);
      });
  });
};
