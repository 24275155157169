import LoadingPage from "pages/LoadingPage";
import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { pagesRouter } from "router";

function App() {
  return (
    <div>
      <Suspense fallback={<LoadingPage />}>
        <RouterProvider router={pagesRouter} />
      </Suspense>
    </div>
  );
}

export default App;
