import { API } from "api/apiURLs";
import { customFetcher } from "api/fetcher";

const fetcherApplicationJson = customFetcher("application/json");
const fetcherMultipartData = customFetcher("multipart/form-data");

const getProjects = async () => {
  const res = await fetcherApplicationJson.get(API.projectsList());
  return res;
};

const createProject = async (data: {
  name: string;
  photo: FormData;
  description: string;
  workspace: number;
  slug_prefix: string;
}) => {
  const res = await fetcherMultipartData.post(API.createProject(), data, {
    headers: { workspace: data.workspace },
  });
  return res;
};

const editProject = async (
  id: number,
  data: { name: string; description: string; photo: FormData; workspace: number },
) => {
  const res = await fetcherMultipartData.patch(API.editProject(id), data, {
    headers: { workspace: data.workspace },
  });
  return res;
};

const deleteProject = async (id: number, workspace: number) => {
  const res = await fetcherApplicationJson.delete(API.deleteProject(id), {
    headers: { workspace },
  });
  return res;
};

const generateSlug = async (name: string) => {
  const res = await fetcherApplicationJson.post(API.generateSlug(), { name });
  return res;
};
const validateSlug = async (slug: string) => {
  const res = await fetcherApplicationJson.post(API.validateSlug(), { slug });
  return res;
};
export { getProjects, createProject, editProject, deleteProject, generateSlug, validateSlug };
