import {
  SignUpProps,
  LoginProps,
  ResetPasswordProps,
  ConfirmResetPasswordProps,
  ChangePasswordProps,
} from "../types";
import { API } from "../apiURLs";
import { customFetcher } from "api/fetcher";
import { AxiosResponse } from "axios";
import { IUser } from "store/types/registration";

const fetcherApplicationJson = customFetcher("application/json");

const signUp = async (data: SignUpProps) => {
  const res = await fetcherApplicationJson.post(API.singUp(), data);
  return res;
};

const login = async (reqData: LoginProps): Promise<AxiosResponse<{ key: string; user: IUser }>> => {
  const res = await fetcherApplicationJson.post(API.login(), reqData);
  return res;
};

const logout = async () => {
  const res = fetcherApplicationJson.post(API.logout());
  return res;
};

const resetPassword = async (data: ResetPasswordProps) => {
  const res = await fetcherApplicationJson.post(API.resetPassword(), data);
  return res;
};
const resetPasswordConfirm = async (data: ConfirmResetPasswordProps) => {
  const res = await fetcherApplicationJson.post(API.resetPasswordConfirm(), data);
  return res;
};
const changePassword = async (data: ChangePasswordProps) => {
  const res = await fetcherApplicationJson.post(API.changePassword(), data);
  return res;
};
const resendEmail = async (data: { email: string }) => {
  const res = fetcherApplicationJson.post(API.resendEmail(), data);
  return res;
};
const confirmEmail = async (reqData: { key: string }) => {
  const res = await fetcherApplicationJson.post(API.confirmEmail(), reqData);
  return res;
};
const getFigmaToken = async (data: { code: string }) => {
  const res = await fetcherApplicationJson.post(API.getFigmaToken(), data);
  return res;
};
const setFigmaState = async (data: { figma_state: string }) => {
  const res = await fetcherApplicationJson.post(API.setFigmaState(), data);
  return res;
};
const sendNotificationsToken = async (token: string) => {
  const res = await fetcherApplicationJson.post(API.sendFirebaseToken(), {
    registration_id: token,
    type: "web",
  });
  return res;
};

export {
  signUp,
  login,
  logout,
  resetPassword,
  resetPasswordConfirm,
  changePassword,
  resendEmail,
  confirmEmail,
  getFigmaToken,
  setFigmaState,
  sendNotificationsToken,
};
