import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { KanbanParams, getBacklog, getColumns, getSprints } from "api/fetchRequests/kanban";
import { BACKLOG } from "constants/sprints";
import { RootState } from "store/store";
import { BacklogState, ISprint } from "store/types/backlog";
import { omit } from "utils";
import { getStatuses } from "utils/kanban";
import { setStatuses } from "./Sidebar";
import { excludeBugsAsChildren, getBacklogDescendant, updateArray } from "utils/backlog";
import { errorNotify } from "hooks/useNotifications";

const initialState: BacklogState = {
  backlog: { ...BACKLOG },
  sprints: [],
  error: null,
  isLoading: false,
  currentItemId: null,
  currentItemIndex: null,
  openedItems: [],
  openedSprints: [],
  allBacklogItems: [],
  selectedItems: [],
  draggingItemId: null,
  searchVal: "",
};

export const fetchBacklogData = createAsyncThunk(
  "fetchBacklogData",
  async (
    { id, workspace }: { id: number; workspace: number; params?: KanbanParams },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const kanbanStatuses = state.kanbanPage.statuses;
    try {
      const sprintsRes = await getSprints(id);
      const sprints = sprintsRes.data;
      const res = await getBacklog(id);
      if (!kanbanStatuses.length) {
        const res = await getColumns(id, workspace);

        const statuses = getStatuses(res.data);
        dispatch(setStatuses(statuses));
      }

      const validSprints: ISprint[] = sprints.map((sprint) =>
        omit(
          {
            ...sprint,
            items: [...sprint.screens, ...sprint.elements],
          },
          ["screens", "elements"],
        ),
      );
      const sprintsWithoutBugs = validSprints.map((sprint) => ({
        ...sprint,
        items: excludeBugsAsChildren(sprint.items),
      }));

      const validBacklogItems = [...res.data.screens, ...res.data.bugs];
      const validBacklog = { ...BACKLOG, items: [...validBacklogItems] };
      const backlogWithoutBugs = {
        ...validBacklog,
        items: excludeBugsAsChildren(validBacklogItems),
      };
      const allBacklogItems = getBacklogDescendant([...validSprints, validBacklog]);
      if (sprints.length) {
        return {
          backlog: backlogWithoutBugs,
          sprints: sprintsWithoutBugs,
          allBacklogItems,
        };
      }
      return { backlog: backlogWithoutBugs, allBacklogItems };
    } catch (error) {
      errorNotify("Please try again later!");
      console.log(error);
    }
  },
);

export const backlogSlice = createSlice({
  name: "backlog",
  initialState,
  reducers: {
    setOpenedItems: (state, { payload }) => {
      state.openedItems = payload;
    },
    setOpenedSprints: (state, { payload }) => {
      const sprintIdsWithoutDuplicates = [...new Set(payload)] as number[];
      state.openedSprints = sprintIdsWithoutDuplicates;
    },
    setSelectedItems: (state, { payload }) => {
      state.selectedItems = payload;
    },
    setSprintsItems: (state, { payload }) => {
      state.sprints = payload;
    },
    setCurrentItemId: (state, { payload }) => {
      state.currentItemId = payload;
    },
    setDraggingId: (state, { payload }) => {
      state.draggingItemId = payload;
    },
    setSearchVal: (state, { payload }) => {
      state.searchVal = payload;
    },
    setCurrentItemIndex: (state, { payload }) => {
      state.currentItemIndex = payload;
    },
    updateSprints: (state, { payload }) => {
      const newSprints = payload.sprints ? updateArray(state.sprints, payload.sprints) : [];
      const validSprints = newSprints.length ? newSprints : state.sprints;
      const allBacklogItems = getBacklogDescendant([
        ...validSprints,
        payload.backlog ?? state.backlog,
      ]);
      state.allBacklogItems = allBacklogItems;
      if (newSprints.length) {
        state.sprints = newSprints;
      }
      if (payload.backlog) {
        state.backlog = payload.backlog;
      }
    },
    getState: (state) => {
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBacklogData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBacklogData.fulfilled, (state, { payload }) => {
        state.backlog = payload?.backlog || { ...BACKLOG };
        state.sprints = payload?.sprints || [];
        state.allBacklogItems = payload?.allBacklogItems || [];
        state.isLoading = false;
      })
      .addCase(fetchBacklogData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  getState,
  setSprintsItems,
  updateSprints,
  setCurrentItemId,
  setCurrentItemIndex,
  setSelectedItems,
  setOpenedItems,
  setOpenedSprints,
  setDraggingId,
  setSearchVal,
} = backlogSlice.actions;

export default backlogSlice.reducer;
