import { Flex, Loader } from "@mantine/core";

const LoadingPage = () => {
  return (
    <Flex w="100vw" h="100vh" justify="center" align="center">
      <Loader />
    </Flex>
  );
};

export default LoadingPage;
