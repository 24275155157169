import { useState, useEffect, useRef, useMemo } from "react";
import { onMessage, getToken } from "firebase/messaging";
import { messaging } from "firebaseConfig";
import useNotifications from "./useNotifications";
import { sendNotificationsToken } from "api/fetchRequests/auth";
import { useLocation } from "react-router";

const usePushNotifications = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const location = useLocation();
  const isSend = useRef(false);
  const { pushNotify, errorNotify } = useNotifications();

  const validLocation = useMemo(() => {
    return !location.pathname.includes("login") && !location.pathname.includes("registration");
  }, [location]);

  const Sendrequest = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification User Permission Granted.");

        return getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        })
          .then(async (currentToken) => {
            if (currentToken) {
              await sendNotificationsToken(currentToken);
              isSend.current = true;
            } else {
              console.log("Failed to generate the registration token.");
            }
          })
          .catch((err) => {
            console.log("An error occurred when requesting to receive the token.", err);
            errorNotify(err);
          });
      } else {
        console.log("User Permission Denied.");
      }
    });
  };

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });

  useEffect(() => {
    if (notification?.title) {
      pushNotify(notification.title, notification.body);
    }
  }, [notification]);

  useEffect(() => {
    if (!isSend.current && validLocation) {
      Sendrequest();
    }
  }, [location]);

  onMessageListener()
    .then((payload: any) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => {
      errorNotify(err);
    });
};

export default usePushNotifications;
